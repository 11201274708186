import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Rif = () => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  useEffect(() => {
    setsliderLength(sliderRef.current.props.children.length)
  }, [sliderLength, sliderCurrent])

  var settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
  }

  return (
    <div className="card">
      <div className="card-header" id="headingEight">
        <div className="row">
          <div className="col-md-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                08. RIF.LA
              </button>
            </p>
          </div>
          <div className="offset-md-1 col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                2019
              </button>
            </p>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                Identity, Typeface Design, Web
              </button>
            </p>
          </div>
          <div className="col-md-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseEight"
              aria-expanded="true"
              aria-controls="collapseEight"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseEight"
        className="collapse"
        aria-labelledby="headingEight"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 order-2 order-md-1">
              <p>
                The Identity focuses on the sneaker history revolving around
                sports and its graphic elements including scoreboard and
                typefaces that held names of the greatest athletes, such as
                Michael Jordan. The identity also helps to expand the community
                who share a common language in the sneaker world.
              </p>

              <div className="viewmore">
                <Link to="/projects/rifla">
                  <h2>View full case study ↗</h2>
                </Link>
              </div>
            </div>
            <div className="col-md-8 order-1 order-md-2">
              <div className="slide-indicator">
                <p>
                  (0{sliderCurrent} / 0{sliderLength})
                </p>
              </div>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <div className="col-10 embed-responsive embed-responsive-16by9 d-block m-auto">
                    <video
                      className="embed-responsive-item border-video"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source
                        src="https://res.cloudinary.com/rwh/video/upload/v1607106075/rgrq/static/sliders/slider-rifla-1.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607106075/rgrq/static/sliders/slider-rifla-2.jpg"
                    alt="Billboard"
                  />
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607106075/rgrq/static/sliders/slider-rifla-3.jpg"
                    alt="placeholder"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Rif
