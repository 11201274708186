import React from "react"

import Fifa from "./fifa"
import FifaMuseum from "./fifaMuseum"
import Nike from "./nike"
import ArtCenter from "./artCenter"
import PuebloSonidero from "./puebloSonidero"
import PuebloSonideroMedia from "./puebloSonideroMedia"
import Mola from "./mola"
import Rif from "./rif"
import Typefaces from "./typefaces"
import Memories from "./memories"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"



const Projects = () => {
  return (
    <div className="container-fluid projects">
      <div className="projects__header">
        <div className="row">
          <div className="col-md-3">
            <h2>Projects</h2>
          </div>
          <div className="offset-md-2 col-md-3 d-none d-md-block">
            <h2>Year</h2>
          </div>
          <div className="col-md-2 d-none d-md-block">
            <h2>Category</h2>
          </div>
        </div>
      </div>

      <div className="accordion" id="accordionExample">
        <Fifa />
        <FifaMuseum />
        <Nike />
        <ArtCenter />
        <PuebloSonidero />
        <PuebloSonideroMedia />
        <Mola />
        <Rif />
        <Typefaces />
        <Memories />
      </div>
    </div>
  )
}
export default Projects
