import React from "react"
import { Link } from "gatsby"

const Nike = () => {
  return (
    <div className="card">
      <div className="card-header" id="headingThree">
        <div className="row">
          <div className="col-md-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                03. Sueños: Nike X Mexico National Team
              </button>
            </p>
          </div>
          <div className="offset-md-1 col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                2020
              </button>
            </p>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Commercial
              </button>
            </p>
          </div>
          <div className="col-md-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseThree"
        className="collapse"
        aria-labelledby="headingThree"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 order-2 order-md-1">
              <p>
                A proposed short by NIKE presenting the Mexico Men’s National
                Team for the 2026 World Cup in the United States. Inspired by
                the cross-cultural perspective of many immigrants in the city of
                Los Angeles raised by multiple cultures, traditions, and sounds.
              </p>

              <div className="viewmore">
                <h2>
                  <Link to="/projects/sueños">Play (audio on) ↗</Link>
                </h2>
              </div>
            </div>
            <div className="offset-md-1 col-md-6 order-1 order-md-2">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  controls
                  loop
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607105681/rgrq/static/sue%C3%B1os/rgrq_nike_video_mexico_ts9c0c.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Nike
