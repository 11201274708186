import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Mola = () => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  useEffect(() => {
    setsliderLength(sliderRef.current.props.children.length)
  }, [sliderLength, sliderCurrent])

  var settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
  }
  return (
    <div className="card">
      <div className="card-header" id="headingSeven">
        <div className="row">
          <div className="col-md-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                07. Maria of Los Angeles
              </button>
            </p>
          </div>
          <div className="offset-md-1 col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                2019
              </button>
            </p>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                Book Design, Typeface Design, Web 
              </button>
            </p>
          </div>
          <div className="col-md-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseSeven"
        className="collapse"
        aria-labelledby="headingSeven"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 order-2 order-md-1">
              <p>
                MOLA is an image-based publication dedicated to preserve murals
                of the Virgen de Guadalupe and to share her history in Los
                Angeles, which was once known as Nuestra Señora Reina de Los
                Ángeles.
              </p>
              <p>
                The publication shares the stories of the Virgen de Guadalupe,
                her murals, the artists, and the immigrant community in the city
                of Los Angeles.
              </p>
              <div className="viewmore">
                <Link to="/projects/mola">
                  <h2>View full case study ↗</h2>
                </Link>
              </div>
            </div>
            <div className="col-md-8 order-1 order-md-2">
              <div className="slide-indicator">
                <p>
                  (0{sliderCurrent} / 0{sliderLength})
                </p>
              </div>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607105968/rgrq/static/sliders/slider-mola-1.jpg"
                    alt="Billboard"
                  />
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607105968/rgrq/static/sliders/slider-mola-2.jpg"
                    alt="placeholder"
                  />
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607105972/rgrq/static/sliders/slider-mola-3.jpg"
                    alt="placeholder"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Mola
