import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Fifa = () => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  useEffect(() => {
    setsliderLength(sliderRef.current.props.children.length)
  }, [sliderLength, sliderCurrent])

  var settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
  }
  return (
    <div className="card">
      <div className="card-header" id="headingTwo">
        <div className="row">
          <div className="col-md-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                02. Fifa Museum
              </button>
            </p>
          </div>
          <div className="offset-md-1 col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                2020
              </button>
            </p>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Identity
              </button>
            </p>
          </div>
          <div className="col-md-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseTwo"
        className="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 order-2 order-md-1">
              <p>
                The Museum identity focuses on the community, the representation
                of soccer, and highlighting how global soccer can be. The
                design’s foundation is constructed by abstracting the graphic
                elements and composition of a soccer pitch creating a window of
                knowledge.
              </p>
              <div className="viewmore">
                <Link to="/projects/fifa-museum">
                  <h2>View full case study ↗</h2>
                </Link>
              </div>
            </div>
            <div className="col-md-8 order-1 order-md-2">
              <div className="slide-indicator">
                <p>
                  (0{sliderCurrent} / 0{sliderLength})
                </p>
              </div>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607362829/rgrq/static/sliders/slider-fifamuseum-1_gc5qa1.jpg"
                    alt="Billboard"
                  />
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607362829/rgrq/static/sliders/slider-fifamuseum-2_byf7jx.jpg"
                    alt="placeholder"
                  />
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607362829/rgrq/static/sliders/slider-fifamuseum-3_ymr84o.jpg"
                    alt="placeholder"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Fifa
