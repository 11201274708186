import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Typefaces = () => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  useEffect(() => {
    setsliderLength(sliderRef.current.props.children.length)
  }, [sliderLength, sliderCurrent])

  var settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
  }
  return (
    <div className="card">
      <div className="card-header" id="headingNine">
        <div className="row">
          <div className="col-lg-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseNine"
                aria-expanded="true"
                aria-controls="collapseNine"
              >
                09. Font Design
              </button>
            </p>
          </div>
          <div className="offset-lg-1 col-lg-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseNine"
                aria-expanded="true"
                aria-controls="collapseNine"
              >
                2019–Now
              </button>
            </p>
          </div>
          <div className="col-lg-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseNine"
                aria-expanded="true"
                aria-controls="collapseNine"
              >
                Typeface Design
              </button>
            </p>
          </div>
          <div className="col-lg-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="true"
              aria-controls="collapseNine"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseNine"
        className="collapse"
        aria-labelledby="headingNine"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 order-2 order-md-1">
              <p>
                Custom typefaces created for various projects inspired by Los
                Angeles, culture, and traditions. From a variable font to a
                deconstructed grotesque I have found myself in love with
                creating forms and language in typography.
              </p>
              <div className="viewmore">
                <h2>
                  <Link to="/projects/typefaces">View all fonts ↗</Link>
                </h2>
              </div>
            </div>
            <div className="col-lg-8 order-1 order-md-2">
              <div className="slide-indicator">
                <p>
                  (0{sliderCurrent} / 0{sliderLength})
                </p>
              </div>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607326597/rgrq/static/sliders/slide-typography-1_yigwbo.jpg"
                    alt="Billboard"
                  />
                </div>
                <div>
                  <div className="embed-responsive embed-responsive-16by9 d-block m-auto">
                    <video
                      className="embed-responsive-item border-video"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source
                        src="https://res.cloudinary.com/rwh/video/upload/v1607326597/rgrq/static/sliders/slide-typography-2_pegbv8.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div>
                  <div className="embed-responsive embed-responsive-16by9 d-block m-auto">
                    <video
                      className="embed-responsive-item border-video"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source
                        src="https://res.cloudinary.com/rwh/video/upload/v1607326597/rgrq/static/sliders/slide-typography-3_qfxexo.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Typefaces
