import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Memories = () => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  useEffect(() => {
    setsliderLength(sliderRef.current.props.children.length)
  }, [sliderLength, sliderCurrent])

  var settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
  }
  return (
    <div className="card">
      <div className="card-header" id="headingTen">
        <div className="row">
          <div className="col-lg-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTen"
                aria-expanded="true"
                aria-controls="collapseTen"
              >
                10. Memories
              </button>
            </p>
          </div>
          <div className="offset-lg-1 col-lg-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTen"
                aria-expanded="true"
                aria-controls="collapseTen"
              >
                Now and Then
              </button>
            </p>
          </div>
          <div className="col-lg-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTen"
                aria-expanded="true"
                aria-controls="collapseTen"
              >
                Too much to write in here
              </button>
            </p>
          </div>
          <div className="col-lg-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTen"
              aria-expanded="true"
              aria-controls="collapseTen"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseTen"
        className="collapse"
        aria-labelledby="headingTen"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 order-2 order-md-1">
              <p>
                Is a collection of work produced in time that may or may not
                have seen the light of day. Memories is a place in space where I
                keep some work I personally enjoy doing such as photography,
                drawing, print, and videography.
              </p>
              <div className="viewmore">
                <h2>
                  <Link to="/memories">View memories ↗</Link>
                </h2>
              </div>
            </div>
            <div className="col-lg-8 order-1 order-md-2">
              <div className="slide-indicator">
                <p>
                  (0{sliderCurrent} / 0{sliderLength})
                </p>
              </div>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <div className="embed-responsive embed-responsive-16by9 d-block m-auto">
                    <video
                      className="embed-responsive-item border-video"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source
                        src="https://res.cloudinary.com/rwh/video/upload/v1607326390/rgrq/static/sliders/slide-memories-1_palsrd.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div>
                  <img
                    className="slide_img img-fluid d-block m-auto"
                    src="https://res.cloudinary.com/rwh/image/upload/v1607326394/rgrq/static/sliders/slide-memories-2_cfmiz2.jpg"
                    alt="placeholder"
                  />
                </div>
                <div>
                  <div className="embed-responsive embed-responsive-16by9 d-block m-auto">
                    <video
                      className="embed-responsive-item border-video"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source
                        src="https://res.cloudinary.com/rwh/video/upload/v1607326390/rgrq/static/sliders/slide-memories-3_obos1s.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Memories
