import React from "react"
import { Link } from "gatsby"

const PuebloSonideroMedia = () => {
  return (
    <div className="card">
      <div className="card-header" id="headingSix">
        <div className="row">
          <div className="col-md-4">
            <p>
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                06. Pueblo Cumbiambero: Installation
              </button>
            </p>
          </div>
          <div className="offset-md-1 col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                2020
              </button>
            </p>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <p className="mb-0">
              <button
                className="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                Transmedia Design, Sound Design
              </button>
            </p>
          </div>
          <div className="col-md-1 d-none d-md-block">
            <button
              className="btn btn-block text-left collapsed cheveron"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              <img className="img-fluid" src="/Down.svg" alt="cheveron icon" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseSix"
        className="collapse"
        aria-labelledby="headingSix"
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 order-2 order-md-1">
              <p>
                Is based on the migrating paths of Cumbia focusing on Mexico
                City and Monterrey. The viewer is transported by a turntable
                that allows them to modify the tempo in a song, taking them from
                Colombia to Mexico City to Monterrey as these places have
                lowered the tempo to slow the dance and even time. The
                installation also celebrates one of the great Sonidero masters
                of Monterrey, El Señor Don Gabriel Dueñez, also known as Sonido
                Dueñez and Cumbias Rebajadas (chopped and screwed Cumbias)
              </p>
              <div className="viewmore">
                <Link to="/projects/pueblo-sonidero-mediatecture">
                  <h2>View full case study ↗</h2>
                </Link>
              </div>
            </div>
            <div className="col-md-8 order-1 order-md-2">
              <div className="embed-responsive embed-responsive-16by9 d-block m-auto">
                <video
                  className="embed-responsive-item border-video"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607105859/rgrq/static/sliders/slider-pueblosonideromedia-1.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PuebloSonideroMedia
